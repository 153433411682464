<template>
    <div class="row" id="RelatoriolinhaPrincipal">
        <Breadcrumb titulo="Relatório de pesquisa" :items="itensBreadcrumb"/>
        <div class="col" style="margin-left:15px; margin-right:15px;margin-top:15px;" id="colunaTabs">
            <div class="h-100">
                <b-row id="abasInformacao">
                    <div id="abas" class="px-4 py-2" v-for="aba in abas" :data-id="aba.id" @click="abrirInformacao(aba)" :class="currentAba == aba.id ? 'active' : ''">
                        {{aba.titulo}}
                    </div>
                </b-row>
                <b-row id="abasConteudo" class="px-2 py-2 " style="height: calc(90vh - 100px)">
                    <b-col style="padding:2px;" class="h-100">
                        <b-row v-if="currentAba != null">
                            <b-col class="py-2 mx-1">
                                <div id="holdBtnRelatorio">
                                    <div class="btnFormularioRelatorios" @click="abrirFiltros()">Filtrar</div>
                                    <div class="btnFormularioRelatorios" @click="exportarConsultaFormulario()">Exportar Relatório</div>
                                </div>
                            </b-col>
                        </b-row>
                        <b-container style="padding:4px;" v-for="aba in abas" v-if="currentAba == aba.id" fluid class="h-100">
                            <div v-for="(item, index) in abas" v-if="aba.id == `${index}` " class="bg-white app-scroll-custom" style="height: calc(80vh - 150px)">
                                <b-table v-if="!loaders.consultandoCamposDoFormulario"
                                         striped
                                         hover
                                         :items="formulariosCadastrados"
                                         :fields="fieldsVisiveis"
                                         style="font-size:12px">
                                    <template #head(more)>
                                        <i :class="`fas ${verMaisCampos ? 'fa-times' : 'fa-plus'} fa-fw float-right mr-2 cursor-pointer formulario-mais-campos-opener`"
                                           title="Mais campos..."
                                           @click="verMaisCampos = !verMaisCampos"></i>
                                        <ul v-if="verMaisCampos" :class="`app-scroll-custom${loaders.invertendoVisibilidadeDoCampo ? ' loading' : ''}`">
                                            <li v-for="campo in camposDoFormulario"
                                                @click="inverterVisibilidadeCampo(campo)"
                                                class="text-truncate"
                                                :title="campo.NomeCampo">
                                                <i v-if="loaders.invertendoVisibilidadeDoCampo != campo.Id"
                                                   :class="`fas ${campo.EmDestaqueNoRelatorio ? 'fa-check' : ''} mr-1 fa-fw`"></i>
                                                <i v-else class="fas fa-sync-alt fa-spin mr-1 fa-fw text-primary"></i>
                                                {{campo.NomeCampo}}
                                            </li>
                                        </ul>
                                    </template>
                                    <template #cell(informacao)="data">
                                        <div class="fomularioInfomacoes" @click="formularioInformacao(data.item)">
                                            <i class='fas fa-info-circle fa-2x '></i>
                                        </div>
                                    </template>

                                    <template #cell()="{ value }">
                                        <span v-html="value"></span>
                                    </template>
                                    <template #cell(more)="data">
                                        <i class="fas fa-trash-alt float-right ml-2 formularioIconsExcluir" @click='excluirRegistro(data)'></i>
                                    </template>

                                </b-table>
                                <center v-if="loaders.consultandoCamposDoFormulario || loaders.consultandoDadosDePesquisa" class="mt-3">
                                    <b-spinner small class="mr-2 text-cliente"></b-spinner>
                                    Carregando...
                                </center>
                                <center v-if="!loaders.consultandoCamposDoFormulario && !loaders.consultandoDadosDePesquisa && (!formulariosCadastrados || formulariosCadastrados.length == 0)">
                                    Nenhum formulário encontrado para os filtros selecionados.
                                </center>
                            </div>
                            <div class="my-3 text-secondary d-flex pl-0" id="paginacaoFormularioRespostas">
                                <div class="d-flex w-0">
                                    <paginacao-seta ref="paginacao_seta"
                                                    :offset="quantidadePagina"
                                                    :selecionado="pagina"
                                                    @eventoPaginacao="eventoPaginacao" />

                                </div>
                                <div class="d-flex w-0">
                                    <span id="registros">Registros: {{quantidadeFormulario}}</span>
                                </div>
                            </div>
                        </b-container>
                    </b-col>
                </b-row>
            </div>
        </div>
        <ModalInformacaoFormulario ref="modalInformacaoFormulario"
                                   :dadosInformacao="dadosInformacao" />

        <ModalFiltrosFormulario v-if="filtrosConsulta"
                                ref="modalFiltrosFormulario"
                                :filtrosConsulta="filtrosConsulta"
                                :camposDoFormulario="camposDoFormulario.filter(item => [3, 4, 5].includes(item.Tipo))"
                                @carregaConsultaFiltro="carregaConsultaFiltro" />
        <ModalFiltroExportarFormulario ref="modalFiltrosExportarFormulario"
                                       :dadosExportar="dadosAbaSelecionada"
                                       @carregaExportacaoFormulario="carregaExportacaoFormulario" />

    </div>
</template>
<script>
    import ModalInformacaoFormulario from '@/components/Formularios/ModalInformacaoFormulario.vue';
    import PaginacaoSeta from '@/components/tabelas/paginacao/PaginacaoSeta';
    import ModalFiltrosFormulario from '@/components/Formularios/ModalFiltrosFormulario.vue';
    import ModalFiltroExportarFormulario from '@/components/Formularios/ModalFiltroExportarFormulario.vue';
    import Breadcrumb from "@/components/breadcrumb/Breadcrumb";
    import axios from 'axios'
    export default {
        name: 'ModalCriarFormulario',
        components: {
            Breadcrumb,
            PaginacaoSeta,
            ModalInformacaoFormulario,
            ModalFiltrosFormulario,
            ModalFiltroExportarFormulario
        },
        data: function () {
            return {
                itensBreadcrumb: [
                    {
                        id: '1',
                        classe: 'fas fa-cogs',
                        texto: 'Cadastros básicos',
                        link: '/cadastros-basicos'
                    },
                    {
                        id: '2',
                        classe: 'fa fa-scroll',
                        texto: 'Formulários',
                        link: '/gerenciarformularios'
                    },
                    {
                        id: '3',
                        classe: 'fa fa-search',
                        texto: 'Relatório de pesquisa',
                        link: '/formularios-relatorios'
                    },
                ],
                abas: null,
                currentAba: null,
                fieldsFormularios: [{ key: 'informacao', label: "Informações" }, { key: 'protocolo', label: "Código" }, { key: 'DataCadastro', label: "Data" }],
                dadosInformacao: null,
                dadosExportar: null,
                camposDoFormulario: [],
                formulariosCadastrados: null,
                quantidadeFormulario: null,
                quantidadePagina: 1,
                pagina: 1,
                dadosAbaSelecionada: null,
                verMaisCampos: false,
                filtrosConsulta: null,
                loaders: {
                    consultandoCamposDoFormulario: false,
                    consultandoDadosDePesquisa: false,
                    invertendoVisibilidadeDoCampo: null
                }
            }
        },
        computed: {
            fieldsVisiveis() {
                let fields = [...(this.fieldsFormularios ?? []), ...(this.camposDoFormulario.filter(campo => campo.EmDestaqueNoRelatorio).map(campo => ({
                    key: campo.Id,
                    label: campo.NomeCampo
                })) ?? [])];
                if (this.camposDoFormulario && this.camposDoFormulario.length > 0) fields.push("more");
                return fields;
            },
            filtros() {
                return {
                    Inicio: this.filtrosConsulta?.Inicio,
                    Fim: this.filtrosConsulta?.Fim,
                    Respostas: this.camposDoFormulario.filter(campo => this.filtrosConsulta[campo.Id]).map(campo => ({
                        CampoId: campo.Id,
                        Resposta: this.filtrosConsulta[campo.Id]
                    })),
                    FormularioId: this.dadosAbaSelecionada.formularioId,
                    Pagina: this.pagina
                };
            }
        },
        created() {
            this.carregaFormularios();
        },

        methods: {

            eventoPaginacao(valor) {
                this.pagina = valor;
                this.carregaConsultaFiltro();
            },

            carregaConsultaFiltro() {
                this.loaders.consultandoDadosDePesquisa = true;
                this.quantidadePagina = 1;
                this.quantidadeFormulario = 0;
                this.formulariosCadastrados = [];
                axios.post('/api/formularios/FiltroFormulario', this.filtros)
                    .then((response) => {
                        this.loaders.consultandoDadosDePesquisa = false;
                        this.quantidadePagina = Math.ceil(response.data.Quantidade / 10);
                        this.quantidadeFormulario = response.data.Quantidade;
                        this.formulariosCadastrados = response.data.Dados.map((item, index) => {
                            return Object.assign({
                                informacao: index,
                                DataCadastro: item.Data,
                                formularioId: item.FormularioId,
                                controlePesquisa: item.ControlePesquisa,
                                protocolo: item.Codigo
                            }, ...item.Respostas.map(resposta => ({
                                [resposta.FormularioCampoId]: resposta.Resposta.split("\n").join("<br /><span class='text-info'>+</span> ")
                            })));
                        });
                    }).catch(() => {
                        this.loaders.consultandoDadosDePesquisa = false;
                    });
            },

            carregaFormularios() {
                $("#caixa_carregamento").show();
                axios.get('/api/formularios/BuscarListaFomularios')
                    .then((response) => {
                        let dadosMap = response.data;
                        this.abas = dadosMap.map((item, index) => ({ id: index, titulo: item.NomeFormulario, formularioId: item.Id }))
                        $("#caixa_carregamento").hide();
                    }, (error) => {
                        console.log(error);
                        $("#caixa_carregamento").hide()
                    });
            },

            excluirRegistro(dados) {
                $("#caixa_carregamento").show();



                axios.post('/api/formularios/excluirResposta/' + dados.item.controlePesquisa)
                    .then((response) => {
                        let indexExclusao = this.formulariosCadastrados.findIndex(f => f.controlePesquisa == dados.item.controlePesquisa);
                        this.formulariosCadastrados.splice(indexExclusao, 1);
                        this.$bvToast.toast("Item removido com sucesso!", {
                            title: `Aviso`,
                            toaster: 'b-toaster-top-right',
                            solid: true,
                            variant: 'success',
                            appendToast: false,
                            autoHideDelay: 3000,
                            noCloseButton: true
                        });


                        $("#caixa_carregamento").hide();
                    }, (error) => {
                        console.log(error);
                        $("#caixa_carregamento").hide()
                    });
            },

            abrirInformacao(dados) {
                this.currentAba = dados.id;
                this.dadosAbaSelecionada = dados;
                this.quantidadePagina = 1;
                this.quantidadeFormulario = 0;
                this.verMaisCampos = false;
                this.camposDoFormulario = [];
                this.formulariosCadastrados = [];
                this.loaders.consultandoCamposDoFormulario = true;
                axios.get(`api/formularios/${dados.formularioId}/campos`).then(response => {
                    this.loaders.consultandoCamposDoFormulario = false;
                    this.camposDoFormulario = response.data ?? [];
                    this.filtrosConsulta = Object.assign(
                        { Inicio: this.filtrosConsulta?.Inicio, Fim: this.filtrosConsulta?.Fim, },
                        ...this.camposDoFormulario.map(campo => (
                            {
                                [campo.Id]: null
                            }
                        ))
                    );
                    this.carregaConsultaFiltro();
                }).catch(() => {
                    this.loaders.consultandoCamposDoFormulario = false;
                });
            },

            formularioInformacao(dados) {
                this.dadosInformacao = dados;
                this.$refs["modalInformacaoFormulario"].show();
            },

            abrirFiltros() {
                this.$refs["modalFiltrosFormulario"].show();
            },

            exportarConsultaFormulario(dados) {
                this.dadosExportar = dados;
                this.$refs["modalFiltrosExportarFormulario"].show();
            },

            carregaExportacaoFormulario(dados) {
                $("#caixa_carregamento").show();
                this.$refs["modalFiltrosExportarFormulario"].hide();
                let data = JSON.stringify({
                    "FormulariosFiltros": this.filtros,
                    "ColunasExportarFormulario": dados,

                });
                var config = {
                    method: 'post',
                    url: '/api/formularios/ExportarConsultaFormulario',
                    responseType: 'arraybuffer',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                };
                axios(config)
                    .then((response) => {
                        const link = document.createElement("a");
                        link.href = window.URL.createObjectURL(new Blob([response.data]))
                        link.download = `Formulario_${Date.now()}.xlsx`
                        link.click();
                        link.remove();
                        $("#caixa_carregamento").hide();
                    }, (error) => {
                        console.log(error);
                        $("#caixa_carregamento").hide()
                    });
            },

            inverterVisibilidadeCampo(campo) {
                if (this.loaders.invertendoVisibilidadeDoCampo) return;
                this.loaders.invertendoVisibilidadeDoCampo = campo.Id;
                campo.EmDestaqueNoRelatorio = !campo.EmDestaqueNoRelatorio;
                axios.put(`api/formularios/campos/${campo.Id}/${campo.EmDestaqueNoRelatorio ? "destacar" : "ocultar"}-campo-no-formulario`).then(() => {
                    this.loaders.invertendoVisibilidadeDoCampo = null;
                    if (campo.EmDestaqueNoRelatorio) this.carregaConsultaFiltro();
                }).catch(() => {
                    this.loaders.invertendoVisibilidadeDoCampo = null;
                });
            }

        }
    }



</script>
<style scoped>

    #registros {
        padding: 6px;
    }

    #abas {
        padding-top: 0.5rem;
    }

    #paginacaoFormularioRespostas {
        font-size: 13px;
    }

    #RelatoriolinhaPrincipal {
        padding: 10px;
        padding-top: 0px;
    }

    #abasInformacao > div {
        background-color: #f2f2f2;
        cursor: pointer;
        color: #7d7d7d;
        border-right: 1px #e8e8e8 solid;
        margin-right: 5px;
    }

        #abasInformacao > div.active {
            background-color: #e8e8e8;
            cursor: default;
            font-weight: 600;
        }

    #abasConteudo {
        background-color: #e8e8e8;
        cursor: default;
    }

    #holdBtnRelatorio {
        float: right;
        align-items: center;
    }

    .fomularioInfomacoes {
        cursor: pointer;
    }

    .btnFormularioRelatorios {
        display: inline-block;
        padding: 5px 15px;
        cursor: pointer;
        border-bottom: 2px solid transparent;
        background-color: var(--cor-primaria-cliente);
        color: #FFF;
        margin-left: 15px;
        font-size: 14px;
    }

        .btnFormularioRelatorios:hover {
            filter: brightness(1.1);
        }

    .btnBuscaPagina {
        color: #666;
        font-weight: bold;
        font-size: 20px;
        display: inline-block;
        cursor: pointer;
    }

    .formulario-mais-campos-opener {
        color: #212529;
        width: 20px;
        height: 20px;
        padding: 4px;
    }

        .formulario-mais-campos-opener.fa-times {
            background-color: #212529;
            color: #fff;
            border-radius: 50%;
        }

        .formulario-mais-campos-opener + ul {
            position: absolute;
            background-color: #fff;
            list-style-type: none;
            padding: 0;
            max-height: 50vh;
            overflow-y: auto;
            box-shadow: 0 0 2px rgba(0 0 0 / .5);
            right: 20px;
            margin-top: 27px;
            max-width: 300px;
        }

            .formulario-mais-campos-opener + ul.loading {
                color: var(--cinza-4);
            }

            .formulario-mais-campos-opener + ul > li {
                padding: 5px 10px 5px 5px;
                border-bottom: 1px var(--cinza-3) solid;
                white-space: nowrap;
                background-color: #fff;
                transition: all ease-in-out .3s;
            }

                .formulario-mais-campos-opener + ul > li:hover {
                    background-color: var(--cinza-3);
                }

            .formulario-mais-campos-opener + ul.loading > li:hover {
                background-color: #fff;
            }

    .formularioIconsExcluir {
        margin-right:10px;
    }
</style>