<template>
    <b-container fluid>
        <b-row>
            <Breadcrumb titulo="Gerenciar Formulário"
                        :items="itemsBreadcrumb"/>
        </b-row>
        <b-row class="mx-0 my-0">
            <b-col class="p-0" style="margin:5px;  background-color: #e8e8e8">
                <b-row class="mx-0 my-0">
                    <b-col>
                        <input placeholder="Nome do formulário" type="text" id="caixaPesquisaFormulario" v-model="termoFormulario" />
                        <div id="btnPesquisaFormulario" @click='buscaFormularioTermo()'>
                            <i class="fas fa-search"></i>
                        </div>
                    </b-col>
                    <b-col>
                        <div id="btnNovoFormulario" @click='abrirCriarFormulario()'>Novo formulário</div>
                    </b-col>
                </b-row>
                <b-row class="mx-0 my-0">
                    <b-col>
                        <div id="caixaTabelaFomularios">
                            <b-table striped hover :items="formulariosCadastrados" :fields="fieldsFormularios" style="font-size:12px">
                                <template #cell(NomeFormulario)="data">
                                    <i :class="(data.detailsShowing ? 'fas fa-angle-down' : 'fas fa-angle-right') "
                                       style="font-size: 12px; padding: 0px 5px; cursor: pointer; "
                                       @click="data.toggleDetails"></i>
                                    {{data.item.NomeFormulario}}
                                </template>
                                <template #row-details="data">
                                    <b-row>
                                        <b-col>
                                            <div id="btnNovoCampo" @click='abrirCriarFormularioCampos(data)'> + Novo campo</div>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col>

                                            <b-table striped hover class="thead-light" style="border:1px solid #CCC; font-size:12px;"
                                                     :items="data.item.Campos"
                                                     :fields="fieldsFormulariosCampos">
                                                <template #cell(FormularioCampoTipo)="data">
                                                    {{tiposCampos[data.item.FormularioCampoTipo]?.texto}}
                                                    <i class="fas fa-trash-alt float-right ml-2 formularioIcons" @click='excluirFormularioCampo(data)'></i>
                                                    <i class="fas fa-pen float-right ml-2 formularioIcons" @click='editarFormularioCampos(data)'></i>
                                                </template>
                                            </b-table>

                                        </b-col>
                                    </b-row>
                                </template>
                                <template #cell(DataCadastro)="data">
                                    {{data.item.DataCadastro}}
                                    <i class="fas fa-copy float-right ml-2 formularioIcons" @click='copiarLinkFormulario(data)'></i>
                                    <i class="fas fa-trash-alt float-right ml-2 formularioIcons" @click='excluirFormulario(data)'></i>
                                    <i class="fas fa-pen float-right ml-2 formularioIcons" @click='editarFormulario(data)'></i>
                                </template>
                            </b-table>

                        </div>

                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <ModalCriarFormulario ref="modalCriarFormulario"
                              :formularioDados="formularioDados"
                              @carregaNovoFormulario="carregaNovoFormulario"
                              @carregaEdicaoFormulario="carregaEdicaoFormulario" />
        <ModalCriarFormularioCampos ref="modalCriarFormularioCampos"
                                    :formularioDados="formularioCamposDados"
                                    :formularioId="formularioId"
                                    @carregaNovoCampoFormulario="carregaNovoCampoFormulario"
                                    @carregaEdicaoCampoFormulario="carregaEdicaoCampoFormulario" />
    </b-container>

</template>

<script>
    import Breadcrumb from '@/components/breadcrumb/Breadcrumb.vue';
    import ModalCriarFormulario from '@/components/Formularios/ModalCriarFormulario.vue';
    import ModalCriarFormularioCampos from '@/components/Formularios/ModalCriarFormularioCampos.vue';
    import axios from 'axios';
    export default {
        name: 'FomularioManutenção',
        components: {
            Breadcrumb,
            ModalCriarFormulario,
            ModalCriarFormularioCampos
        },
        data: function () {
            return {

                itemsBreadcrumb: [
                    {
                        id: '2',
                        classe: 'fa fa-cogs',
                        texto: 'Cadastros Básicos',
                        link: '/cadastros-basicos'
                    },

                    {
                        id: '3',
                        classe: 'fa fa-scroll',
                        texto: 'Formulários',
                        link: '/gerenciarformularios'
                    },
                    {
                        id: '3',
                        classe: 'fa fa',
                        texto: 'Gerenciar Formulário',
                        link: '/gerenciarformularios'
                    }
                ],

                termoFormulario: null,
                formulariosCadastrados: null,
                formularioDados: null,
                formularioCamposDados: null,
                formularioId: null,
                fieldsFormularios: [{ key: 'NomeFormulario', label: "Formulário" }, { key: 'DataCadastro', label: "Data criação" }],

                fieldsFormulariosCampos: ['Ordem', { key: 'NomeCampo', label: 'Nome do campo' }, { key: 'FormularioCampoTipo', label: 'Tipo' }],
                tiposCampos: [
                    { valor: 0, texto: "Caixa de Texto" },
                    { valor: 1, texto: "Numérico" },
                    { valor: 2, texto: "Data" },
                    { valor: 3, texto: "Lista Suspensa" },
                    { valor: 4, texto: "Caixa de Seleção" },
                    { valor: 5, texto: "Multipla escolha" },
                ]
            }
        },
        created() {
            this.carregaFormularios();
        },
        methods: {
            carregaFormularios() {
                $("#caixa_carregamento").show();
                axios.get('/api/formularios/BuscarListaFomularios')
                    .then((response) => {
                        this.formulariosCadastrados = response.data;
                        $("#caixa_carregamento").hide();
                    }, (error) => {
                        console.log(error);
                        $("#caixa_carregamento").hide();
                    });
            },
            buscaFormularioTermo() {
                $("#caixa_carregamento").show();
                if (this.termoFormulario == null || this.termoFormulario == "") {
                    this.termoFormulario = "**";
                }
                axios.get('/api/formularios/BuscarFomularioTermo/' + this.termoFormulario)
                    .then((response) => {
                        this.termoFormulario = null;
                        this.formulariosCadastrados = response.data;
                        $("#caixa_carregamento").hide();
                    }, (error) => {
                        console.log(error);
                        $("#caixa_carregamento").hide();
                    });
            },

            editarFormulario(dados) {
                this.formularioDados = dados.item;
                this.$refs["modalCriarFormulario"].show();
            },
            editarFormularioCampos(dados) {
                
                this.formularioCamposDados = dados.item;
                this.$refs["modalCriarFormularioCampos"].show();
            },
            carregaNovoFormulario(dadosNovoFormulario) {
                this.formulariosCadastrados.unshift(dadosNovoFormulario);
                this.formularioNotificacao("Novo formulario criado com sucesso!");
            },
            carregaNovoCampoFormulario(dadosNovoCampoFormulario) {
                let Formulario = this.formulariosCadastrados.find(f => f.Id == this.formularioId);
                Formulario.Campos.push(dadosNovoCampoFormulario);
                this.formularioNotificacao("Novo formulario criado com sucesso!");
            },
            carregaEdicaoFormulario(dadosNovoFormulario) {
                let indexFormularioAntigo = this.formulariosCadastrados.findIndex(f => f.Id == this.formularioDados.Id);
                this.formulariosCadastrados[indexFormularioAntigo].Descricao = dadosNovoFormulario.Descricao;
                this.formulariosCadastrados[indexFormularioAntigo].NomeFormulario = dadosNovoFormulario.NomeFormulario;
                if(this.formulariosCadastrados[indexFormularioAntigo].RespostaAutomaticaDTO && this.formulariosCadastrados[indexFormularioAntigo].RespostaAutomaticaDTO != {}){
                    this.formulariosCadastrados[indexFormularioAntigo].RespostaAutomaticaDTO.Assunto = dadosNovoFormulario.RespostaAutomaticaDTO.Assunto ?? '';
                    this.formulariosCadastrados[indexFormularioAntigo].RespostaAutomaticaDTO.Id = dadosNovoFormulario.RespostaAutomaticaDTO.Id ?? null;
                    this.formulariosCadastrados[indexFormularioAntigo].RespostaAutomaticaDTO.Mensagem = dadosNovoFormulario.RespostaAutomaticaDTO.Mensagem ?? '';
                    this.formulariosCadastrados[indexFormularioAntigo].RespostaAutomaticaDTO.SetorId = dadosNovoFormulario.RespostaAutomaticaDTO.SetorId ?? null;
                    this.formulariosCadastrados[indexFormularioAntigo].RespostaAutomaticaDTO.FormularioCampoId = dadosNovoFormulario.RespostaAutomaticaDTO.FormularioCampoId ?? null;
                }
                this.formularioNotificacao("Ediação realizada com sucesso!");
            },
            carregaEdicaoCampoFormulario(dadosEdicao) {
                this.formularioCamposDados.Ordem = dadosEdicao.Ordem;
                this.formularioCamposDados.NomeCampo = dadosEdicao.NomeCampo;
                this.formularioCamposDados.FormularioCampoTipo = dadosEdicao.FormularioCampoTipo;
                this.formularioCamposDados.FormularioId = dadosEdicao.FormularioId;
                this.formularioCamposDados.Id = dadosEdicao.Id;
                this.formularioCamposDados.Opcoes = dadosEdicao.Opcoes;
                this.formularioCamposDados.OpcoesArquivos = dadosEdicao.OpcoesArquivos;
                this.formularioCamposDados.Especificar = dadosEdicao.Especificar;
                this.formularioCamposDados.Obrigatorio = dadosEdicao.Obrigatorio;
                this.formularioNotificacao("Ediação realizada com sucesso!");
            },
            abrirCriarFormulario: function () {
                this.formularioDados = null;
                this.$refs["modalCriarFormulario"].show();
            },
            abrirCriarFormularioCampos: function (rowData) {
                this.$nextTick(() => {
                    this.formularioCamposDados = null;
                    this.formularioId = rowData.item.Id;

                });
                this.$refs["modalCriarFormularioCampos"].show();
            },
            excluirFormularioCampo(dados) {
                this.$bvModal.msgBoxConfirm("Deseja excluir o campo?", {
                    title: 'Atenção',
                    okTitle: 'Confirmar',
                    cancelTitle: 'Cancelar',
                    okVariant: 'success',
                    cancelVariant: 'white',
                    centered: true
                }).then(confirmado => {
                    if (confirmado) {
                        var data = {
                            "Id": dados.item.Id
                        };
                        axios.put('/api/formularios/ExcluirFomularioCampos', data)
                            .then((response) => {
                                //busca o formular
                                let formularioIndex = this.formulariosCadastrados.findIndex(f => f.Id == dados.item.FormularioId);
                                //  busca  o campo
                                let indexCampo = this.formulariosCadastrados[formularioIndex].Campos.findIndex(f => f.Id == dados.item.Id);
                                this.formulariosCadastrados[formularioIndex].Campos.splice(indexCampo, 1);
                                $("#caixa_carregamento").hide();
                                this.formularioNotificacao("Exclusao realizada com sucesso!");
                            }, (error) => {
                                console.log(error);
                                $("#caixa_carregamento").hide();
                        });
                    }
                })
            },
            excluirFormulario(dados) {
                this.$bvModal.msgBoxConfirm("Deseja excluir o formulário?", {
                    title: 'Atenção',
                    okTitle: 'Confirmar',
                    cancelTitle: 'Cancelar',
                    okVariant: 'success',
                    cancelVariant: 'white',
                    centered: true
                }).then(confirmado => {
                    if (confirmado) {
                        $("#caixa_carregamento").show();
                        var data = {
                            "Id": dados.item.Id
                        };
                        axios.put('/api/formularios/ExcluirFomulario', data)
                            .then((response) => {
                                let indexExclusao = this.formulariosCadastrados.findIndex(f => f.Id == dados.item.Id);
                                this.formulariosCadastrados.splice(indexExclusao, 1);
                                $("#caixa_carregamento").hide();
                                this.formularioNotificacao("Exclusao realizada com sucesso!");
                            }, (error) => {
                                console.log(error);
                                $("#caixa_carregamento").hide();
                            });
                    }
                })
            },
            formularioNotificacao(mensagem) {
                this.$bvToast.toast(mensagem, {
                    title: `Aviso`,
                    toaster: 'b-toaster-top-right',
                    solid: true,
                    variant: 'success',
                    appendToast: false,
                    autoHideDelay: 3000,
                    noCloseButton: true
                });
            },
            copiarLinkFormulario(dados) {
                var copyText = location.host + "/pesquisa/form?item=" + dados.item.Id;
                navigator.clipboard.writeText(copyText);
                this.formularioNotificacao("Link copiado com sucesso!!")
            }


        },
    }
</script>
<style scoped>
    #caixaPesquisaFormulario {
        background-color: #FFF;
        color: var(--cinza-5);
        width: 50%;
        border: 1px solid transparent;
        outline: none;
        height: 30px;
        margin-top: 15px;
        font-size: 12px;
    }

        #caixaPesquisaFormulario:hover, #caixaPesquisaFormulario:focus {
            outline: none;
        }

    #btnPesquisaFormulario {
        display: inline-block;
        background-color: rgb(25, 177, 189);
        width: 30px !important;
        border: 6px solid rgb(25, 177, 189);
        color: #FFF;
        text-align: center;
        cursor: pointer;
        font-size: 12px;
    }

        #btnPesquisaFormulario:hover {
            opacity: 0.8
        }

    #btnNovoFormulario {
        /*   display: inline-block;*/
        background-color: rgb(25, 177, 189);
        width: 130px !important;
        border: 6px solid rgb(25, 177, 189);
        color: #FFF;
        text-align: center;
        cursor: pointer;
        margin-top: 15px;
        float: right;
        font-size: 12px;
    }

        #btnNovoFormulario:hover {
            opacity: 0.8
        }

    #btnNovoCampo {
        /*   display: inline-block;*/
        background-color: rgb(25, 177, 189);
        width: 105px !important;
        border: 6px solid rgb(25, 177, 189);
        color: #FFF;
        text-align: center;
        cursor: pointer;
        margin: 0px 0px 10px 0px;
        float: right;
        font-size: 12px;
    }

        #btnNovoCampo:hover {
            opacity: 0.8
        }

    #caixaTabelaFomularios {
        background-color: #FFF;
        margin: 15px 0px;
    }

    .formularioIcons {
        padding: 0px 5px;
        cursor: pointer;
    }

</style>