<template>
	<b-modal ref="modalFiltroFormulario"
			 modal-class="modalFiltroFormulario"
			 dialog-class="modalInfomacoesFormulario"
			 content-class="contentModalInfomacoes"
			 v-model="exibe"
			 body-class="app-modal-custom-header"
			 header-class="app-modal-custom-header"
			 footer-class="app-modal-custom-footer"
			 title="Filtro de formulários"
			 hide-footer>
		<b-container class="bv-example-row pt-2" style="background-color: #f3f3f3; margin-bottom:20px">
			<b-row>
				<b-col class="data-field">
					<label for="filtro-data-inicial">Data inicial</label>
					<input type="date" id="filtro-data-inicial" v-model="filtrosConsulta.Inicio" />
				</b-col>
				<b-col class="data-field">
					<label for="filtro-data-final">Data final</label>
					<input type="date" id="filtro-data-final" v-model="filtrosConsulta.Fim" />
				</b-col>
			</b-row>
			<b-row v-for="dupla in filtrosEmDuplas">
				<b-col v-for="campo in dupla" class="data-field" :title="campo.NomeCampo">
					<label :for="`consulta-campo-${campo.Id}`" class="text-truncate">{{campo.NomeCampo}}</label>
					<select :id="`consulta-campo-${campo.Id}`" v-model="filtrosConsulta[campo.Id]">
						<option :value="null">Selecione uma opção...</option>
						<option v-for="opcao in campo.Opcoes.split('\n')" :value="opcao">{{opcao}}</option>
					</select>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<div id="holdBtns">
						<div id="btnCancelarFiltroformulario" class="btnModalFiltrarFormulario" @click="hide()">Cancelar</div>
						<div id="btnFiltrarformulario" class="btnModalFiltrarFormulario" @click="filtrarConsulta">Filtrar</div>
					</div>
				</b-col>
			</b-row>
		</b-container>

	</b-modal>

</template>
<script>
	import InputDataTabela from '@/components/inputs/InputDataTabela.vue';
	export default {
		name: 'modalFiltroFormulario',
		components: {
			InputDataTabela,
		},
		props: {
			filtrosConsulta: null,
			camposDoFormulario: null
		},
		data() {
			return {
				exibe: false
			}
		},
		computed: {
			filtrosEmDuplas() {
				if (!this.camposDoFormulario || this.camposDoFormulario.length == 0) return [];
				let filtros = [];
				this.camposDoFormulario.forEach((item, index) => {
					let group = Math.ceil((index + 1) / 2) - 1;
					if (!filtros[group]) filtros[group] = [];
					filtros[group].push(item);
				});
				return filtros;
			}
		},
		methods: {
			show() {
				this.exibe = true;
			},
			hide() {
				this.exibe = false
			},
			filtrarConsulta() {
				this.$emit("carregaConsultaFiltro");
				this.hide();
			}
		},
	}
</script>

<style scoped>

	.inputFormulario {
		background-color: var(--cinza-2);
		color: var(--cinza-5);
		width: 100%;
		border: 1px solid var(--cinza-4);
		outline: none;
		height: 28px;
	}

		.inputFormulario:hover, .inputFormulario:focus {
			outline: none !important;
		}

	.labelFormulario {
		margin: 0px;
		font-size: 14px;
		color: #666;
	}

	#holdBtns {
		display: block;
		float: right;
		margin: 20px 0px;
	}

	.btnModalFiltrarFormulario {
		display: inline-block;
		padding: 5px 15px;
		cursor: pointer;
	}

		.btnModalFiltrarFormulario:hover {
			opacity: 0.8;
		}

	#btnCancelarFiltroformulario {
		border-bottom: 2px solid #808080;
		margin-right: 15px;
	}

	#btnFiltrarformulario {
		border-bottom: 2px solid transparent;
		background-color: #3adf5b;
		color: #FFF;
	}

	.inputFiltroFormulario {
		background-color: #FFF;
		color: var(--cinza-5);
		width: 100%;
		border: 1px solid var(--cinza-4);
		outline: none;
		height: 35px;
	}

		.inputFiltroFormulario:hover, .inputFiltroFormulario:focus {
			outline: none !important;
		}

		.data-field > label {
			max-width: 200px;
		}
</style>