<template>
    <b-modal ref="modalExportacaoFormulario"
             modal-class="modalExportacaoFormulario"
             dialog-class="modalExportacaoFormulario"
             content-class="contentModalExportar"
             v-model="exibe"
             body-class="app-modal-custom-header"
             header-class="app-modal-custom-header"
             footer-class="app-modal-custom-footer"
             title="Selecione as colunas desejada"
             hide-footer>
        <b-container>
            <b-row>
                <b-col>
                    <input type="checkbox" id="opcaoTodos" value="opcaoTodos" v-model="selecionaTodos" style="width: 15px; height: 15px;" @change="selecionarTodos">&nbsp;
                    <span style="color: #666;font-size: 14px; font-weight:bold">Todos</span>
                </b-col>
            </b-row>
            <b-row v-for="(itens, index) in filtros">
                <b-col v-for="(item, index) in itens">
                    <input type="checkbox" :name="item.Id" :ref="`campo-${item.Id}`"  style=" width: 15px; height: 15px;">&nbsp;
                    <span style="color: #666;font-size: 14px; font-weight:bold">{{item.Nome}}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <div id="holdBtns">
                        <div id="btnCancelarExportar" class="btnModalExpostar" @click="hide()">Cancelar</div>
                        <div id="btnExportarformulario" class="btnModalExpostar" @click="exportarFiltros()">Exportar</div>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </b-modal>

</template>
<script>
    import axios from 'axios'
    export default {
        name: 'modalExportacaoFormulario',

        props: {
            dadosExportar: null
        },
        data() {
            return {
                loading: false,
                exibe: false,
                filtrosCarregado: null,
                filtros: null,
                selecionaTodos:false
            }
        },
        methods: {
            show() {
                this.exibe = true;
                this.$nextTick(() => {
                    this.carregaFiltroExportar();
                });
            },
            hide() {
                this.exibe = false;
            },
            selecionarTodos(){
                for(var i in this.filtrosCarregado){
                     let campo = `campo-${this.filtrosCarregado[i].Id}`;
                    this.$refs[`${campo}`][0].checked = this.selecionaTodos;
                };
            },
            carregaFiltroExportar() {
                $("#caixa_carregamento").show()
                axios.get('/api/formularios/BuscarFomulario/' + this.dadosExportar.formularioId)
                    .then((response) => {
                        this.filtrosCarregado = response.data.Campos.map(m => ({ Nome: m.NomeCampo, Id: m.Id}));
                        this.filtros = this.separar();
                        $("#caixa_carregamento").hide();
                    }, (error) => {
                        console.log(error);
                        $("#caixa_carregamento").hide()
                    });
            },
            separar() {
                let result = [];
                let c = 0;
                let quantidade = Math.ceil(this.filtrosCarregado.length / 2)
                for (var i = 0; i < quantidade; i++) {
                    result.push(this.filtrosCarregado.slice(c, c + 2));
                    c += 2;
                }
                return result;
            },
            exportarFiltros(){
                let itensFiltragem = [];
                for(var i in this.filtrosCarregado){
                     let campo = `campo-${this.filtrosCarregado[i].Id}`;

                    if(this.$refs[`${campo}`][0].checked){
                       itensFiltragem.push({CampoId : this.filtrosCarregado[i].Id, NomeCampo :this.filtrosCarregado[i].Nome})
                    }
                    
                };
               
                this.$emit('carregaExportacaoFormulario', itensFiltragem)
                this.selecionaTodos = false;
                
              
            }

        },
    }
</script>

<style scoped>

    .inputFormulario {
        background-color: var(--cinza-2);
        color: var(--cinza-5);
        width: 100%;
        border: 1px solid var(--cinza-4);
        outline: none;
        height: 28px;
    }

        .inputFormulario:hover, .inputFormulario:focus {
            outline: none !important;
        }

    .labelFormulario {
        margin: 0px;
        font-size: 14px;
        color: #666;
    }

    #holdBtns {
        display: block;
        float: right;
        margin: 20px 0px;
    }

    .btnModalExpostar {
        display: inline-block;
        padding: 5px 15px;
        cursor: pointer;
    }

        .btnModalExpostar:hover {
            opacity: 0.8;
        }

    #btnCancelarExportar {
        border-bottom: 2px solid #808080;
        margin-right: 15px;
    }

    #btnExportarformulario {
        border-bottom: 2px solid transparent;
        background-color: #3adf5b;
        color: #FFF;
    }
</style>